body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.height_100 {
	height: 100%;
}
.width_100 {
	width: 100%;
}
.width_30 {
	width: 30%;
}
