.card {
    background-color: #4545A5;
    border-radius: 15px;
    box-shadow: '0px 10px 20px 0px';
    overflow: hidden;
    padding: 10px;
    width:100%;
  }
  
  .card-body {
    display: flex;
    background-color: #4545A5;
    align-items: center;
    justify-content: space-between;
  }
  
  .left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: #4545A5;
  }
  .bottom-section{
    display: flex;
    background-color: #4545A5;
    margin-left: 18px;
    margin-bottom: 10px;
    max-height: 61px;
  }
  .bottom-right-section{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-left: 10px;
    
  }
  
  .right-section {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 6px;
    height:38%;
    width: 20%;
    align-items: center;
  }
  .date{
    margin-bottom: 5px;
  }
  .month{
    margin-bottom: 5px;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4545A5;
  }
  
  .left-section svg {
    height: 20px;
    width: 20px;
    padding: 0.5px;
  }
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .ExpandedCard{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1%!important;
    z-index: 1;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    background-color: #FFFFFF!important;
    width:100%;
    height: 100%;
  }
  .card-header{
    padding: 10px;
    background-color: #4545A5;  
    border-radius: 10px 10px 0px 0px;
    width: 200%;
    height: 100%!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left{
    display: flex;
    flex-direction: column;
    align-items:left;
    justify-content: center;
    padding: 10px;
  }
  .middle{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF!important;
    border-radius: 5px;
    padding: 12px;
  }
  .right{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .right-sect{
    text-align: center;
    margin-right: 20px;
  }
  .options{
    display: flex;
    background-color: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .opt-btn1,
  .opt-btn2{
    cursor: pointer;
    border-radius:  0px 0px 10px 10px;
    background-color:rgba(69, 69, 165, 0.1);
    width: 287px!important;
    height: 44px!important;
    text-align: center;
    border: none;
    
  }
  .opt-btn1:hover{
    background-color: rgba(69, 69, 165, 0.3);
  }
  .opt-btn2:hover{
    background-color: rgba(69, 69, 165, 0.3);
  }
  .cross{
    position: absolute;
    right: 48px;
    top: 16%;
    cursor: pointer;
    color: #FFFFFF;
  }
  .eye-btn{
    display: flex;
    border-radius: 10px!important;
    border: 1px solid #000000!important;
    width: 20%!important;
    height: 36px!important;
    align-items: center!important;
  }
  @media only screen and (max-width:768px){
    .ExpandedCard{
      top: 10%;
      left: 10%;
      width: 80%;
    }
    .card-header{
      width: 100%;
    }
    .left{
      padding: 5px;
    }
    .middle{
      padding: 10px;
    }
    .right-sect{
      margin-right: 10px;
    }
    .options {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .opt-btn1,
    .opt-btn2 {
      width: 100%;
      height: 36px!important;
    }
  
    .eye-btn {
      margin-top: 5px;
      width: 40%;
    }
  
  }
  .Result-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .Award-content{
    display: flex;
    align-items: center;
    justify-content:center;
    gap: 50px;
  }
  .medal{
    margin-top: 10%;
  }
  .result-btn{
    display: flex!important;
    align-items: left!important;
    justify-content:space-between!important;
    background-color:rgba(69, 69, 165, 0.1)!important;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    
  }
  .result-btn1{
    background-color: #42BBEF!important;
    display: flex;
    align-items: center!important;
    justify-content:space-between!important;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    padding: 20px!important;
  }
  .result-btn2{
    background-color: #FFD000!important;
    display: flex;
    align-items: center!important;
    justify-content:space-between!important;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    padding: 20px!important;
  }
  .result-btn3{
    background-color: rgba(69, 69, 165, 0.1)!important;
    display: flex;
    align-items: center!important;
    justify-content:space-between!important;
    border-radius: 5px;
    height: 40px;
    width: 300px;
    padding: 20px!important;
  }
 
  .left-result{
    display: flex;
    flex-direction: column;
    }
  .right-result{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .right-header{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-left: 100;
    height: 60px;
  }
  .head-result{
    background-color: transparent!important;
    display: flex;
    align-items: center!important;
    justify-content:space-between!important;
    padding: 15px!important;
  }
  .table{
    width: 90%;
    margin-top:5px;
    padding-bottom: 10px;
    border-collapse: collapse!important;
  }
  .col{
    width: 80px!important;
  }
  .table th{
    width: 50px;
  }

 .col1{
    border-right: 1px!important;
    border-left: 1px !important;
    border-radius: 50px;
    height: 50px!important;
    width: 50px!important;
    background: rgba(69, 69, 165, 0.1);
  }
  .col2{
    border-right: 1px!important;
    border-left: 1px !important;
    border-radius: 50px;
    height: 50px!important;
    width: 50px!important;
    background:  rgba(83, 208, 100, 0.3);
  }
  .col3{
    border-right: 1px!important;
    border-left: 1px !important;
    border-radius: 50px;
    height: 50px!important;
    width: 50px!important;
    background:rgba(255, 211, 1, 0.2);
  }
  .title-tabl{
    text-align: right!important;
    padding: 20px!important;
  }
.certificate{
  position: absolute;
}
.target-img{
  display: flex;
  position: relative;
  height: 93px!important;
  width: 93px!important;
  left: 18px;
  top: 5px;
}